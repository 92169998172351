<template>
  <section class="my-16 px-4">
    <div class="bg-[#f09442] rounded-xl p-4 md:flex md:justify-between">
      <div class="text-black text-xl md:text-3xl lg:text-4xl lg:tracking-tight mb-3 md:mb-0 font-semibold">
        Sign up to our mailing list
      </div>
      <form @submit="newsletterSubscribe" v-if="!newsletterSubmit">
        <input
        type="email"
        autocapitalize="none"
        autocomplete="email"
        pattern=".+@example\.com"
        class="inline-block h-[40px] border-0 w-[100%] md:w-[300px] bg-black rounded-[48px] text-[#f09441] placeholder-[#f09441] text-lg py-1 px-4"
        placeholder="Your email here"
        v-model="newsletterEmail"
        :disabled="loading"
        >
        <button type="submit" class="hidden" :disabled="loading">Submit</button>
      </form>
      <div
      v-else
      class="inline-block h-[40px] border-0 w-[300px] bg-[#dbfe52] rounded-[48px] text-[#000] placeholder-[#000] text-lg py-[6px] px-4">
      ✔ Subscribed 
      </div>
    </div>
  </section>

</template>

<script setup>
const newsletterEmail = ref(null)
const newsletterSubmit = ref(false)
const loading = ref(false)

const newsletterSubscribe = async (e) => {
  e.preventDefault()
  console.log(newsletterEmail.value)
  loading.value = true
  try {
    const addToNewsletter = await $fetch('/api/brevo/addToNewsletter', {
      method: 'POST',
      body: JSON.stringify({
        email: newsletterEmail.value
      })
    })
    loading.value = false
    if (addToNewsletter) {
      newsletterSubmit.value = true
    } else {
      newsletterSubmit.value = false
    }
  } catch(e) {
    loading.value = false
    console.log(e)
  }
}

</script>

<style lang="scss">
input[disabled] {
  opacity: 0.4;
}
</style>